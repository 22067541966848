.footer-bg {
  background-image: url('./components/assets/footer.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.contact-bg {
  background-image: url('./components/assets/contact-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
