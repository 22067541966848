@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies&display=swap');

@font-face {
  font-family: 'Hyperspace';
  src: url('./components/assets/fonts/Hyperspace.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hyperspace-Bold';
  src: url('./components/assets/fonts/HyperspaceBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rust';
  src: url('./components/assets/fonts/introheadr-base.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Matter-Medium';
  src: url('./components/assets/fonts/MatterMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Matter-Regular';
  src: url('./components/assets/fonts/MatterRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Matter-SemiBold';
  src: url('./components/assets/fonts/MatterSemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Matter-Light';
  src: url('./components/assets/fonts/MatterLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Matter-Bold';
  src: url('./components/assets/fonts/MatterBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Museo-Slab-1000';
  src: url('./components/assets/fonts/Museo_Slab_1000.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Museo-Slab-700';
  src: url('./components/assets/fonts/Museo_Slab_700.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand_Bold';
  src: url('./components/assets/fonts/Quicksand_Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand_Light';
  src: url('./components/assets/fonts/Quicksand_Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand_Book';
  src: url('./components/assets/fonts/Quicksand_Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
