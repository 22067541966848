@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 600ms ease-in-out;
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
  }
}

.fade-in-slide-up {
  animation: fadeInSlideUp 400ms ease-out;
}

@keyframes scaleIn {
  from {
    scale: 0.5;
  }
  to {
    opacity: 1;
  }
}

.scale-in {
  animation: scaleIn 600ms ease-in-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
  }
}

.slide-down {
  animation: slideDown 600ms ease-in-out;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 600ms ease-in-out;
}

@keyframes scale-back-forth {
  0%,
  100% {
    transform: scale(1); /* Start and end at the original size */
  }
  50% {
    transform: scale(1.2); /* Scale up at midpoint */
  }
}

.scaling-element {
  animation: scale-back-forth 5s ease-in-out infinite; /* Duration and easing */
}

@keyframes translate-x-back-forth {
  0% {
    transform: translateX(0); /* Starting position */
  }
  50% {
    transform: translateX(20px); /* Move to the right */
  }
  100% {
    transform: translateX(0); /* Return to the starting position */
  }
}

.translate-element {
  animation: translate-x-back-forth 5s ease-in-out infinite; /* Apply the animation with smooth transition */
}
@keyframes translate-y-back-forth {
  0% {
    transform: translateY(0); /* Starting position */
  }
  50% {
    transform: translateY(20px); /* Move to the right */
  }
  100% {
    transform: translateY(0); /* Return to the starting position */
  }
}

.translate-y-element {
  animation: translate-y-back-forth 5s ease-in-out infinite; /* Apply the animation with smooth transition */
}

@keyframes translate-x-from-left {
  0% {
    transform: translateX(-150px); /* Starting position */
    opacity: 0;
  }

  100% {
    transform: translateX(0); /* Return to the starting position */
    opacity: 1;
  }
}

.translate-left-left-x {
  animation: translate-x-from-left 500ms ease-out forwards; /* Apply the animation with smooth transition */
}

.shine-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  width: 20%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0.6) 40%
  );
  transform: skewX(-30deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 120%;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-right {
  animation: slideInFromRight 0.4s ease-out;
}

.dot-spinner {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #2fd4bf) content-box;
  -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}
