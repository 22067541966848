@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .autofill-donation-modal-bg:-webkit-autofill,
  .campaign-input:-webkit-autofill,
  .auth-input:-webkit-autofill,
  .user-input:-webkit-autofill {
    -webkit-box-shadow: #f9fafb;
    box-shadow: #f9fafb;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .auction-donation-modal-input {
    @apply autofill-donation-modal-bg;
    @apply campaign-input;
    @apply auth-input;
    @apply user-input;
  }
}

html,
body,
* {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
}

tr:first-child td:first-child {
  border-bottom-left-radius: 5px !important;
}
tr:first-child td:last-child {
  border-bottom-right-radius: 5px !important;
}

.dashboard-sidebar-links.isActive:after {
  clip-path: polygon(100% 100%, 0% 100%, 100% 0%);
  background-image: linear-gradient(
    129deg,
    rgba(193, 224, 255, 1) 35%,
    rgba(224, 205, 245, 1) 100%
  );
}

.dashboard-sidebar-title:after {
  content: '';
  position: absolute;
  z-index: 0;
  width: 62px;
  height: 62px;
  top: 0;
  right: 0;
  clip-path: polygon(100% 100%, 0% 100%, 100% 0%);
  background: rgb(183, 217, 43);
  background: linear-gradient(86deg, rgba(183, 217, 43, 1) 66%, rgba(183, 214, 49, 1) 100%);
}

input[type='radio'].personalize-ecard {
  accent-color: #9863a7 !important;
}

.curve {
  height: 25px;
  width: 25px;
  position: relative;
}

.curve::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  border-bottom-color: #75767b;
  top: -10px;
  left: 12px;
  border-radius: 50%;
  transform: rotate(45deg);
}

ul {
  list-style-type: disc;
  padding-left: 0.5rem;
}

input#image-file.form-control-file,
input#multiple-images.form-control-file {
  display: none;
}
